import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
// import DataTable from 'react-data-table-component';
// import {
//   MDBContainer, MDBCol, MDBRow, MDBModal,
//   MDBTableHead, MDBIcon, MDBModalBody, MDBModalHeader, MDBModalFooter
//   , MDBDataTableV5, MDBInput
// }
//   from 'mdbreact';

import { MDBInput } from 'mdbreact';
import {
    MDBCard, MDBCardBody, MDBCardHeader, MDBTabs, MDBTabsLink, MDBTabsItem, MDBCardText, MDBCardTitle,
    MDBTabsPane, MDBTabsContent, MDBDropdown, MDBSelect, MDBBtn, MDBDatepicker,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBPopover,
    MDBPopoverHeader,
    MDBPopoverBody,
    MDBTooltip,
    MDBIcon,
    MDBPopcofirm,
    MDBPopconfirmMessage,
    MDBPopconfirmButtons
} from 'mdb-react-ui-kit';

import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Radio from '@material-ui/core/Radio'
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import GifterService from '../../services/service.js';
import * as Constants from '../../constants/constants';
import "./Import.scss";
import ImportPreviewJSON from '../../json/preview_import.json';
import ConfirmDialog from '../Common/ConfirmDialog';
import { validatePhone, validateEmail } from '../Common/helper';

class DuplicatePeople extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            name: this.props.name,
            isOpen: this.props.isOpen,
            pageSize: 10,
            page: 0,
            rowCount: props.data?.length || 0,
            data: props.data,
            current: 0,
            selectedIndex: -1,
            selectedPerson: undefined,
            finishDuplicates: false,
            isValidEmail: true,
            results: props.data,
            confirmSaveChanges: false,
            // new_person_phone: '',
            // new_person_email: '',
            // new_person_address: '',
            duplicatesData: [],
            checkedUseImport: {},
            selectedPersons: {},
            new_persons_phone: {},
            new_persons_email: {},
            new_persons_address: {},
            new_persons_details: {}
        }
    }

    goToNextRecord = () => {
        let cur = this.state.current;
        this.setState({ current: cur + 1 });

    }
    goToPrevRecord = () => {
        let cur = this.state.current;
        this.setState({ current: cur - 1 });
    }

    handlePersonChangeSingle = (index, current, event) => {

        var curRec = this.state.current;
        var item = event.target.value;
        var selectedPersons = this.state.selectedPersons;
        selectedPersons[current] = item;
        this.setState({ selectedPerson: item });
        this.setState({ selectedPersons: selectedPersons });

        var duplicateData = this.state.results[current];
        duplicateData.person_id = null;
        duplicateData.is_new = true;
        duplicateData.is_duplicate = false;
        var email = this.state.new_persons_details["new_person_email-"+current];
        var phone = this.state.new_persons_details["new_person_phone-"+current];
        var address = this.state.new_persons_details["new_person_address-"+current]; 


        if (email != "" && email != undefined)
            duplicateData.email = email;
        if (phone != "" && phone != undefined)
            duplicateData.phone = phone;
        if (address != "" && address != undefined)
            duplicateData.address = address;

        var dupResults = [...this.state.duplicatesData];
        dupResults[current] = duplicateData;
        this.setState({
            duplicatesData: dupResults,
            selectedIndex : index
        });

        if (current + 1 >= this.state.rowCount)
            this.setState({ finishDuplicates: true });
    }

    handlePersonChange = (index, current, event) => {

        var curRec = this.state.current;
        var item = event.target.value;
        var selectedPersons = this.state.selectedPersons;
        selectedPersons[current] = item;
        this.setState({ selectedPerson: item });
        this.setState({ selectedPersons: selectedPersons });        

        var duplicateData = this.state.results[current].duplicate_persons[index];
        duplicateData.id = duplicateData.excel_row_no;
        var dupResults = [...this.state.duplicatesData];
        dupResults[current] = duplicateData;
        this.setState({
            duplicatesData: dupResults,
            selectedIndex : index
        });

        var checkedItem = "import-new-" + current + "-" + index;
        // var checkedItems = this.state.checkedUseImport;
        // checkedItems[checkedItem] = true;
        // this.setState({ checkedUseImport: checkedItems });

        this.handleUseImportCore(index, current, checkedItem, true );

        if (current + 1 >= this.state.rowCount)
            this.setState({ finishDuplicates: true });
    }

    handleUseImport = (person_id, event) => {
        console.log(event.target.checked);
        var current = this.state.current;
        var item = event.target.value;
        var checked = event.target.checked;
        this.handleUseImportCore(person_id, current, item, checked )
    }

    handleUseImportCore = (person_id, current, item, checked) => {
        
        // var current = this.state.current;
        // var item = event.target.value;
        // var checked = event.target.checked;
        var checkedItems = this.state.checkedUseImport;
        checkedItems[item] = checked;
        this.setState({ checkedUseImport: checkedItems });       

        var result = this.state.results[current];
        var res = result.duplicate_persons[person_id];
        var result_rev = this.state.data[current].duplicate_persons[person_id];
        if (checked) {
            var email = this.state.new_persons_details["new_person_email-" + current];
            var phone = this.state.new_persons_details["new_person_phone-" + current];
            var address = this.state.new_persons_details["new_person_address-" + current];
            if (email != "" && email != undefined)
                res.email = email;
            if (phone != "" && phone != undefined)
                res.phone = phone;
            if (address != "" && address != undefined)
                res.address = address;
            res.company_name = result.company_name;
            res.title_name = result.title_name;
            res.category_name = result.category_name;
            res.division_name = result.division_name;
            res.p_comp_div_title_id = result.p_comp_div_title_id;
            res.person_email_id = result.person_email_id;
            res.person_address_id = result.person_address_id;
            res.person_category_id = result.person_category_id;
            res.person_phone_id = result.person_phone_id;
            res.title_id = result.title_id;
            res.division_id = result.division_id;
            res.category_id = result.category_id;
            res.company_id = result.company_id;

        }
        else {
            res.email = result_rev.email;
            res.phone = result_rev.phone;
            res.address = result_rev.address;
            res.company_name = result_rev.company_name;
            res.title_name = result_rev.title_name;
            res.category_name = result_rev.category_name;
            res.division_name = result_rev.division_name;
            res.p_comp_div_title_id = result_rev.p_comp_div_title_id;
            res.person_email_id = result_rev.person_email_id;
            res.person_address_id = result_rev.person_address_id;
            res.person_category_id = result_rev.person_category_id;
            res.person_phone_id = result_rev.person_phone_id;
            res.title_id = result_rev.title_id;
            res.division_id = result_rev.division_id;
            res.category_id = result_rev.category_id;
            res.company_id = result_rev.company_id;
        }

        var finalRes = [...this.state.results];
        finalRes[current].duplicate_persons[person_id] = res;
        this.setState({
            results: finalRes
        });

        var duplicateData = res;
        var dupResults = [...this.state.duplicatesData];
        dupResults[current] = duplicateData;
        this.setState({
            duplicatesData: dupResults
        });

        // var duplicateData = this.state.duplicatesData[current];
        // var dupResults = [...this.state.duplicatesData];
        // dupResults[current] = duplicateData; 
        // this.setState({
        //     results:  finalRes
        // }); 

        // }
        //    else{
        //     var result = this.state.results[this.state.current];
        //     var result_rev = this.state.data[this.state.current].duplicate_persons.map((person) => {
        //         if(person.person_id === person_id){
        //             return person;                
        //         }
        //     });
        // var res = result.duplicate_persons.map((person) => {
        //      if(person.person_id === person_id){
        //         return {
        //             ...person,                    
        //           email : result_rev.email,
        //           phone : result_rev.phone,
        //           address : result_rev.address
        //           };                
        //      }
        //      else{
        //         return person; 
        //       }
        // });    
        //     var res = result.duplicate_persons[person_id];
        //     res.email =  result_rev.email;
        //     res.phone = result_rev.phone; 
        //     res.address = result_rev.address;     
        //     var finalRes = [...this.state.results];
        //     finalRes[current].duplicate_persons = res;         
        //     this.setState({
        //        results:  finalRes
        //     }); 
        //    }

    }
    // finishDuplicatePersons = () => {
    //     this.setState({finishDuplicates : event.target.value})
    // }

    inputChangeHandler = (name, fieldName, event) => {
        var value = event.target.value;
        if(fieldName === "email") {
            if(validateEmail(value) || !value ) {
                this.setState({ isValidEmail: true });
            } else {
                this.setState({ isValidEmail: false });
            }
        }
        if(fieldName === "phone" && (validatePhone(value) || !value )) {
            this.setState({
                new_persons_details: {
                    ...this.state.new_persons_details, [name]: value
                }
            });
        } else if(fieldName !== "phone") {
            this.setState({
                new_persons_details: {
                    ...this.state.new_persons_details, [name]: value
                }
            });
        }

        var current = this.state.current;       
        if(this.state.selectedPersons[current]){            
            var results = this.state.results;
            var selected = this.state.selectedPersons[current];
            var arrSelected = selected.split("-");
            var dup_len = results[current].duplicate_persons?.length || 0;
            var index = parseInt(arrSelected[1], 10);
            var checkedItems = this.state.checkedUseImport;
            //var checked = checkedItems["import-new-" + current + "-" + dup_len];
            
            if(index < dup_len && checkedItems["import-new-" + current + "-" + index]){
                results[current].duplicate_persons[index][fieldName] = value;
                this.setState({
                    results: results
                });
            }
            else if(index == dup_len){
                var dupResults = [...this.state.duplicatesData];
                var duplicateData = dupResults[current];
                duplicateData[fieldName] = value;
                dupResults[current] = duplicateData;
                this.setState({
                    duplicatesData: dupResults
                });
            }
        }
    }

    toggleDuplicatePeople = () => {
        //this.props.toggleDuplicatePeople();
        this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
    }

    confirmCancelChanges = (isCancel) => {
        if (isCancel)
        {
            this.clearState();
            this.props.togglePreviewImport();
        }
        this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
    }
    clearState = () =>{
        this.setState({
            duplicatesData: [],
            checkedUseImport: {},
            selectedPersons: {},
            new_persons_phone: {},
            new_persons_email: {},
            new_persons_address: {},
            new_persons_details: {},
            rowCount: 0,
            data: [],
            current: 0,
            selectedPerson: undefined,
            finishDuplicates: false,
            results: [],
            confirmSaveChanges: false
        });
    }

    finishDuplicateCheck = () => {
        this.props.finishDuplicateCheck(this.state.duplicatesData);
        //this.props.toggleDuplicatePeople();
        /*this.setState({
            duplicatesData: [],
            checkedUseImport: {},
            selectedPersons: {},
            new_persons_phone: {},
            new_persons_email: {},
            new_persons_address: {},
            new_persons_details: {},
            rowCount: 0,
            data: [],
            current: 0,
            selectedPerson: undefined,
            finishDuplicates: false,
            results: [],
            confirmSaveChanges: false
        });
        */
       this.clearState();
    }

    componentWillReceiveProps(props) {
        this.setState({
            isOpen: props.isOpen,
            data: JSON.parse(JSON.stringify(props.data || [])),// [...(props.data || [])],
            rowCount: props.data?.length,
            results: props.data
        });

        if (props.data?.length > 0) {
            this.processDuplicates(props.data);
        }
    }

    /*processDuplicate(data){
       var finalRes =  data.map((item) => {
                 return {    
                     exel_row_no: item.excel_row_no,           
                     person_id: item.person_id,
                     first_name: item.first_name,
                     last_name: item.last_name,
                     display_name: item.display_name,
                     dob: item.dob,
                     is_card_signer: item.is_card_signer,
                     category_id: item.category_id,
                     category_name: item.category_name,
                     company_id: item.company_id,
                     company_name: item.company_name,
                     division_id: item.division_id,
                     division_name: item.division_name,
                     title_id: item.title_id,
                     title_name: item.title_name,
                     address: item.address,
                     address_type: item.address_type,
                     phone: item.phone,
                     email: item.email,
                     guests:[]
                 }
             });
         this.setState({
             duplicatesData:  finalRes
         }); 
     }
 */
    processDuplicates(data) {
        var checkedItemsFinal = {};
        var finalRes = data.map((item, current) => {
            item?.duplicate_persons?.map((person, index) => {
                var item = "import-new-" + current + "-" + index;
                // var checkedItem = {};
                // checkedItem[item] = false ;  
                //checkedItemsFinal.push(checkedItem);   
                checkedItemsFinal[item] = false;
                //return checkedItem;
            });

            return {
                person_id: item.person_id,
                first_name: item.first_name,
                last_name: item.last_name,
                display_name: item.display_name,
                dob: item.dob,
                is_card_signer: item.is_card_signer,
                category_id: item.category_id,
                category_name: item.category_name,
                company_id: item.company_id,
                company_name: item.company_name,
                division_id: item.division_id,
                division_name: item.division_name,
                title_id: item.title_id,
                title_name: item.title_name,
                address: item.address,
                address_type: item.address_type,
                phone: item.phone,
                email: item.email,
                guests: []
            }

        });
        this.setState({
            duplicatesData: finalRes,
            checkedUseImport: checkedItemsFinal
        });

    }

    render() {
        return (
            <>
                <MDBModal id="duplicatePeople" staticBackdrop closeOnEsc={false} tabIndex='-1' show={this.props.isOpen} >
                    {/* getOpenState={(e) =>  this.setState({ isOpen: e })}> */}
                    <MDBModalDialog size="md" >
                        <MDBModalContent>
                            {/* <MDBModalHeader>
                      <MDBModalTitle>Modal title</MDBModalTitle>
                      <MDBBtn className='btn-close' color='none' onClick={this.props.toggleImportPeople}></MDBBtn>
                    </MDBModalHeader> */}
                            <MDBModalBody>
                                <div className="flexBox-plain padding-bottom-none">
                                    {/* <div className="flexAlignRight"> */}
                                    <MDBModalTitle className="h6 text-primary">Found a duplicate for
                         {this.props.data?.length > 0 && " " + this.props.data[this.state.current].first_name + " " + this.props.data[this.state.current].last_name}
                                    </MDBModalTitle>
                                    {/* </div>   */}
                                    <div className="flexAlignRight">
                                        <span className="text-dark wrap-none">{this.state.current + 1} of {this.state.rowCount}&nbsp;&nbsp; </span>
                                        <MDBIcon size="2x" icon="times-circle" onClick={this.toggleDuplicatePeople.bind(this)}></MDBIcon>

                                        <ConfirmDialog isOpen={this.state.confirmSaveChanges}
                                            confirmCancelChanges={this.confirmCancelChanges.bind(this)}
                                            displayMessage={"Are you sure you want to cancel the changes?"}>
                                        </ConfirmDialog>
                                        {/* <MDBPopcofirm btnClassName="d-none" modal isOpen={this.state.confirmSaveChanges} >
                                <MDBPopconfirmMessage>Are you sure to cancel the excel import?</MDBPopconfirmMessage>
                                <MDBPopconfirmButtons>
                                    <MDBBtn color='float' size='sm' onClick={this.confirmCancelChanges.bind(this, false)}>
                                     No
                                    </MDBBtn>
                                    <MDBBtn size='sm' onClick={this.confirmCancelChanges.bind(this, true)}>
                                     Yes
                                    </MDBBtn>
                                </MDBPopconfirmButtons>
                           </MDBPopcofirm>                         */}
                                    </div>

                                </div>
                                <div className="fst-italic padding-top-none fs-70">
                                    Please choose one of the below existing People, or create a new Person.
                                </div>
                                
                                <div className="padding-hor-25 padding-top-3">
                                    <label className="text-tertiary">Choose Existing Person</label>
                                </div>

                                <div className="person-rows">
                                    {this.state.results && this.state.results[this.state.current] && this.state.results[this.state.current].duplicate_persons?.map((person, index) => {
                                        var current = this.state.current;
                                        return <>
                                            <div className="person-row padding-top-3">
                                                <div className="row person-row-line">
                                                    <div className="col-half">
                                                        <Radio
                                                            checked={this.state.selectedPersons[current] === current + "-" + index}
                                                            onChange={this.handlePersonChange.bind(this, index, current)}
                                                            value={current + "-" + index}
                                                            name={"radio-button-pick-person-" + current}
                                                            inputProps={{ 'aria-label': person.first_name + ' ' + person.last_name }}
                                                        />
                                                    </div>
                                                    <div className="col-3 padding-left-16 fw-bold">
                                                        {person.first_name + " " + person.last_name}
                                                    </div>
                                                    <div className="col-4">
                                                        {person.company_name}
                                                    </div>
                                                    <div className="col-4">
                                                        {person.title_name}
                                                    </div>
                                                </div>
                                                <div className="row person-row-alt-line">
                                                    <div className="col-half">

                                                    </div>
                                                    <div className="col-3 padding-left-16">
                                                        <input
                                                            class="form-check-input"
                                                            type="checkbox"
                                                            value={"import-new-" + current + "-" + index}
                                                            checked={this.state.checkedUseImport["import-new-" + current + "-" + index]}
                                                            id={"import-new-" + current + "-" + index}
                                                            disabled={this.state.selectedPersons[current] !== current + "-" + index}
                                                            onChange={this.handleUseImport.bind(this, index)}

                                                        />
                                                        <label
                                                            class="form-check-label  padding-left-3"
                                                            for={"import-new-" + this.state.current + "," + index}>
                                                            Use Import
                                                        </label>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="col-12 padding-none">
                                                            <span>
                                                                {person.category_name}
                                                            </span>
                                                        </div>
                                                        <div className="col-12 padding-none">
                                                            <span>
                                                                {person.phone}
                                                            </span>
                                                        </div>
                                                        <div className="col-12 padding-none">
                                                            <span>
                                                                {person.email}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="col-12 padding-none">
                                                            <span>
                                                                {person.division_name}
                                                            </span>
                                                        </div>
                                                        <div className="col-12 padding-none">
                                                            <span>
                                                                {person.address}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </>
                                    })
                                    }

                                    <div className="padding-hor-25 padding-top-3">
                                        <label className="text-tertiary">Create New Person</label>
                                    </div>


                                    {this.state.results && this.state.results.map((person, index) => {
                                        var current = this.state.current;
                                        if (index === current) {
                                            var dup_len = person.duplicate_persons.length;
                                            return <>
                                                <div className="person-row padding-top-3">
                                                    <div className="row person-row-line">
                                                        <div className="col-half">
                                                            <Radio
                                                                checked={this.state.selectedPersons[current] === current + "-" + dup_len}
                                                                onChange={this.handlePersonChangeSingle.bind(this, dup_len, current)}
                                                                value={current + "-" + dup_len}
                                                                name={"radio-button-pick-person-" + current}
                                                                inputProps={{ 'aria-label': person.first_name + ' ' + person.last_name }}
                                                            />
                                                        </div>
                                                        <div className="col-3 padding-left-16 fw-bold">
                                                            {person.first_name + " " + person.last_name}
                                                        </div>
                                                        <div className="col-4">
                                                            {person.company_name}
                                                        </div>
                                                        <div className="col-4">
                                                            {person.title_name}
                                                        </div>
                                                    </div>
                                                    <div className="row person-row-alt-line">
                                                        <div className="col-half">

                                                        </div>
                                                        <div className="col-3">

                                                        </div>
                                                        <div className="col-4">
                                                            <div className="col-12 padding-none">
                                                                <span>
                                                                    {person.category_name}
                                                                </span>
                                                            </div>
                                                            <div className="col-12 padding-none">
                                                                <span>
                                                                    <MDBInput placeholder='Phone:' value={this.state.new_persons_details["new_person_phone-" + current] ? this.state.new_persons_details["new_person_phone-" + current] : ""}
                                                                        name={"new_person_phone-" + current} onChange={this.inputChangeHandler.bind(this, "new_person_phone-" + current, "phone")} type="text" >

                                                                    </MDBInput>
                                                                </span>
                                                            </div>
                                                            <div className="col-12 padding-none">
                                                                <span>
                                                                    <MDBInput placeholder='Email:' value={this.state.new_persons_details["new_person_email-" + current]}
                                                                        name={"new_person_email-" + current} onChange={this.inputChangeHandler.bind(this, "new_person_email-" + current, "email")} type="text" ></MDBInput>
                                                                </span>
                                                                {!this.state.isValidEmail ? <span className="error">Invalid Email!</span> : null }
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="col-12 padding-none">
                                                                <span>
                                                                    {person.division_name}
                                                                </span>
                                                            </div>
                                                            <div className="col-12 padding-none">
                                                                <span>
                                                                    <MDBInput type="textarea" placeholder='Address:' value={this.state.new_persons_details["new_person_address-" + current]}
                                                                        name={"new_person_address-" + current} onChange={this.inputChangeHandler.bind(this, "new_person_address-" + current, "address")}
                                                                        rows={3}>

                                                                    </MDBInput>
                                                                    {/* <textarea
                                                                        className="form-control"                                            
                                                                        rows="2"
                                                                        placeholder='Address'
                                                                        value={this.state.new_person_address}
                                                                    /> */}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    })
                                    }
                                </div>

                                <div className="flexBox-plain padding">

                                    <div className="padding-hor">
                                        <MDBBtn disabled={this.state.current <= 0 ? true : false} onClick={this.goToPrevRecord.bind(this)}>
                                            Previous
                        </MDBBtn>  </div>
                                    <div className="padding-hor">
                                        <MDBBtn disabled={(this.state.current >= this.state.rowCount - 1 || !this.state.selectedPersons[this.state.current] || !this.state.isValidEmail) ? true : false}
                                            onClick={this.goToNextRecord.bind(this)}>
                                            Next
                        </MDBBtn>    </div>
                                    <div className="padding-hor flexAlignRight">
                                        <MDBBtn className={this.state.finishDuplicates ? "d-block" : "hide"} disabled={!this.state.isValidEmail}
                                            onClick={this.finishDuplicateCheck.bind(this)}  >
                                            Finish
                            </MDBBtn>
                                    </div>
                                </div>
                            </MDBModalBody>

                            {/* <MDBModalFooter>
                      <MDBBtn color='secondary' onClick={this.props.toggleImportPeople}>
                        Close
                      </MDBBtn>
                      <MDBBtn>Save changes</MDBBtn>
                    </MDBModalFooter> */}
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            </>
        );
    }
}

export default DuplicatePeople;